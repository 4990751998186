import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Previous PC (2020 Arriety)`}</h2>
    <p>{`From PC Part Picker:`}</p>
    <hr></hr>
    <p><a parentName="p" {...{
        "href": "https://pcpartpicker.com/list/mPL6tp"
      }}>{`PCPartPicker Part List`}</a></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Item`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Price`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`CPU`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/dmw323/amd-cpu-fd8320frhkbox"
            }}>{`AMD FX-8320 3.5 GHz 8-Core Processor`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`$355.48 @ Amazon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Motherboard`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/Yz3RsY/msi-motherboard-970ag46"
            }}>{`MSI 970A-G46 ATX AM3+ Motherboard`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Memory`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/JT2kcf/gskill-memory-f32133c10d8gsr"
            }}>{`G.Skill Sniper Series 8 GB (2 x 4 GB) DDR3-2133 CL10 Memory`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`$49.99 @ Newegg`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Storage`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/dCxfrH/seagate-internal-hard-drive-st1000dm003"
            }}>{`Seagate Barracuda 1 TB 3.5" 7200RPM Internal Hard Drive`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`$90.99 @ Newegg`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Storage`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/dCxfrH/seagate-internal-hard-drive-st1000dm003"
            }}>{`Seagate Barracuda 1 TB 3.5" 7200RPM Internal Hard Drive`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`$90.99 @ Newegg`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Storage`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/mwrYcf/seagate-barracuda-computer-2-tb-35-7200rpm-internal-hard-drive-st2000dm008"
            }}>{`Seagate Barracuda Compute 2 TB 3.5" 7200RPM Internal Hard Drive`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`$54.99 @ Newegg`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Video Card`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/JQQRsY/powercolor-radeon-rx-570-41gb-red-dragon-video-card-axrx-570-4gb-red-dragon"
            }}>{`PowerColor Radeon RX 570 4 GB RED DRAGON Video Card`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Case`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/3WphP6/cougar-case-solution"
            }}>{`Cougar Solution ATX Mid Tower Case`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Power Supply`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/zNK7YJ/evga-power-supply-100b10500kr"
            }}>{`EVGA 500 W 80+ Bronze Certified ATX Power Supply`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Optical Drive`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/2v9KHx/asus-optical-drive-drw24b1stblkbas"
            }}>{`Asus DRW-24B1ST/BLK/B/AS DVD/CD Writer`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`$19.98 @ Amazon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Wireless Network Adapter`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/9fQypg/asus-wireless-network-card-pcen10"
            }}>{`Asus PCE-N10 PCIe x1 802.11a/b/g/n Wi-Fi Adapter`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Case Fan`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/WgGkcf/cougar-turbine-120-4-pack-604-cfm-120-mm-fans-cft12s4"
            }}>{`Cougar Turbine 120 (4-Pack) 60.4 CFM 120 mm Fans`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`$38.97 @ Amazon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Monitor`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://pcpartpicker.com/product/jCYXsY/acer-monitor-etws0hpa01"
            }}>{`Acer S220HQLAbd 21.5" 1920x1080 60 Hz Monitor`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`$208.97 @ Amazon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Custom`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Media Reader`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><em parentName="td">{`Prices include shipping, taxes, rebates, and discounts`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`$910.36`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Generated by `}<a parentName="td" {...{
              "href": "https://pcpartpicker.com"
            }}>{`PCPartPicker`}</a>{` 2020-06-29 10:33 EDT-0400`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <p>{`Note that prices of these items are wildly off from how much I actually paid for them (generally speaking, I paid `}<em parentName="p">{`way`}</em>{` less).`}</p>
    <hr></hr>
    <h2>{`New PC (2020 Baron)`}</h2>
    <h2>{`Goals`}</h2>
    <ul>
      <li parentName="ul">{`game development`}</li>
      <li parentName="ul">{`video editing`}</li>
      <li parentName="ul">{`PC VR`}</li>
      <li parentName="ul">{`PC gaming`}</li>
      <li parentName="ul">{`audio production`}</li>
    </ul>
    <p>{`My old PC was as good as it gets without dropping tons of money on marginal improvements. For the new PC, I want it to handle new technology: new Ryzen CPUs, NVME slot, DDR4 RAM, and better USB3 support. My motherboard was the final "bottleneck". My current CPU is best for price/performance. I have 16gb of RAM through 4 channels but with a new motherboard, I can do so much more.`}</p>
    <p>{`My old PC `}<em parentName="p">{`barely`}</em>{` supports VR -- and the GPU should be good enough to run the likes of Oculus Rift. Upgrading my motherboard lets me upgrade the CPU and the RAM. It also gives me the ability to use NVME for faster SSD speeds.`}</p>
    <h2>{`Parts`}</h2>
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "https://pcpartpicker.com/list/q7vHMc"
      }}>{`PCPartPicker Part List`}</a></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      